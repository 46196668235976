define("discourse/plugins/discourse-journal/discourse/components/journal-comment-button", ["exports", "@glimmer/component", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CommentButton extends _component.default {
    static hidden() {
      return false;
    }
    get i18nKey() {
      return this.args.post.reply_to_post_number ? "comment_reply" : "comment";
    }
    get label() {
      if (!this.args.post.mobileView && !this.args.post.reply_to_post_number) {
        return `topic.${this.i18nKey}.title`;
      } else {
        return "";
      }
    }
    get title() {
      return `topic.${this.i18nKey}.help`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="post-action-menu__comment create fade-out"
          ...attributes
          @action={{@buttonActions.replyToPost}}
          @icon={{if this.args.post.reply_to_post_number "reply" "comment"}}
          @label={{this.label}}
          @title={{this.title}}
        />
      
    */
    {
      "id": "9okLvEH0",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"post-action-menu__comment create fade-out\"],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,2,[\"replyToPost\"]],[52,[30,0,[\"args\",\"post\",\"reply_to_post_number\"]],\"reply\",\"comment\"],[30,0,[\"label\"]],[30,0,[\"title\"]]]],null],[1,\"\\n  \"]],[\"&attrs\",\"@buttonActions\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-journal/discourse/components/journal-comment-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CommentButton;
});